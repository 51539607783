import React from "react"

import Header from "../components/header"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Link from "../components/link";
import Section from "../components/section";

const IndexPage = () => (
  <Layout>
  <Header />
    <SEO title="Hiten Karamchandani" />
    <Section id="about" title="About Me">
      <p>
        I'm a motivated Technical Lead & full-stack engineer who has experience building
        and scaling web applications and APIs in various domains.
      </p>
      <p>
        Leading a software team at Sphere Identity in building a Blockchain
        and Cloud based(Serverless) identity platform.
      </p>
      <p>
        I'm also fascinated with serverless, event-driven architectures, microservices,
        AWS managed services and DevOps.
      </p>
      <p>
        Manages AWS Infrastructure services with the help of Terraform, Serverless,
        and CloudFormation.
      </p>
      <p>
        Developing Apps and APIs with the help of modern Serverless
        and Distributed(Blockchain-based) technologies.
      </p>
      <p>
        Proficient in Agile and Scrum methodology.
      </p>
      <p>
        Apart from work, my favourite interests are Muay Thai, Badminton, Photography, Drawing, and Collecting coins.
      </p>
    </Section>
    <Section id="experience" title="Experience">
      <Link
        title="Sphere Identity"
        link="https://sphereidentity.com"
        desc="Technical Lead, February 2018 - Present"
      />
      <Link
        title="Atom Timing"
        link="https://https://atomtiming.com"
        desc="Full-Stack Developer, July 2017 - March 2019"
      />
      <Link
        title="The University of Auckland"
        link="http://www.student.auckland.ac.nz/"
        desc="Analyst/Developer, September 2017 - February 2018"
      />
      <Link
        title="Orion Health"
        link="https://precisiondrivenhealth.com/mobile-application-for-patient-communication/"
        desc="Research Internship, November 2016 - February 2017"
      />
      <Link
        title="L&T Infotech"
        link="https://www.lntinfotech.com/"
        desc="Senior Software Engineer, August 2012 - May 2016"
      />
    </Section>
    <Section id="publications" title="Publications">
      <Link
        title="Improving Post-Hospital Discharge Management by Implementing the Discharge Summary on a Mobile Application"
        link="https://ieeexplore.ieee.org/document/8653261"
        desc="Published in: 2018 Eleventh International Conference
        on Mobile Computing and Ubiquitous Network (ICMU)"
      />
    </Section>
    <Section id="skills" title="Skills">
      <Link
        title="Languages & Frameworks"
        desc="JavaScript (ES6+), Node.js, Serverless, Jest, Middy.js, Ionic, AngularJS, Gatsby.js React, Kaa IoT, Oracle ADF 11g, J2EE, Oracle Webcenter Portal, Hibernate, EJB, JPA, Servlets, JUnit"
      />
      <Link title="Databases" desc="MongoDB, Google Firebase DB, Oracle, MySQL, SQLite" />
      <Link
        title="Other"
        desc="Amazon Web Services (AWS), Docker, CI/CD, Microservices, API design, Agile/Scrum"
      />
    </Section>
  </Layout>
)

export default IndexPage
